import { useFormStore } from "@/core/form/store/FormStore"
import {
  DashboardBlockDragDropProviderMutation,
  DashboardBlockDragDropProviderMutation$variables,
} from "@/dashboard/context/__generated__/DashboardBlockDragDropProviderMutation.graphql"
import { DashboardBlockDragDropProviderQuery } from "@/dashboard/context/__generated__/DashboardBlockDragDropProviderQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { moveItemWithinArray } from "@utils/array/arrayUtils"
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import { useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  children: React.ReactNode
  dashboardId: GlobalID
}

function DashboardBlockDragDropProvider({ children, dashboardId }: Props) {
  const [{ dashboard }, refetch] =
    Relay.useRefetchableQuery<DashboardBlockDragDropProviderQuery>(
      graphql`
        query DashboardBlockDragDropProviderQuery($dashboardId: ID!) {
          dashboard: node(id: $dashboardId) {
            __typename
            ... on Dashboard {
              mainBlocks: blocks(position: main) {
                edges {
                  node {
                    id
                    ordering
                  }
                }
              }
              sideBlocks: blocks(position: side) {
                edges {
                  node {
                    id
                    ordering
                  }
                }
              }
            }
          }
        }
      `,
      { dashboardId }
    )

  // Refetch to show new ordering if the dashboard was invalidated
  useSubscribeToInvalidationState([dashboardId], () => refetch({ dashboardId }))

  const reorderForm = useFormStore<DashboardBlockDragDropProviderMutation>(
    graphql`
      mutation DashboardBlockDragDropProviderMutation(
        $input: ReorderDashboardBlocksInput!
      ) {
        response: reorderDashboardBlocks(input: $input) {
          node {
            mainBlocks: blocks(position: main) {
              edges {
                node {
                  id
                  ordering
                  position
                }
              }
            }
            sideBlocks: blocks(position: side) {
              edges {
                node {
                  id
                  ordering
                  position
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { dashboardId, sideBlockIds: [], mainBlockIds: [] }
  )

  if (!Relay.isNodeType(dashboard, "Dashboard")) return null

  const mainBlocks = Relay.connectionToArray(dashboard.mainBlocks)
  const sideBlocks = Relay.connectionToArray(dashboard.sideBlocks)

  return <DragDropContext onDragEnd={handleDragEnd}>{children}</DragDropContext>

  async function handleDragEnd(result: DropResult) {
    // Not only do I need to check the index, I should be checking the destination blocks section
    const startIndex = result.source.index
    const destinationIndex = result.destination?.index
    if (
      destinationIndex === undefined ||
      (startIndex === destinationIndex &&
        result.source.droppableId === result.destination?.droppableId)
    )
      return

    // If we're moving within the same block, then just adjust the order
    if (result.source.droppableId === result.destination?.droppableId) {
      const mainBlockIds: GlobalID[] = mainBlocks
        .filter((block) => Boolean(block.id))
        .map((block) => block.id)
      const sideBlockIds: GlobalID[] = sideBlocks
        .filter((block) => Boolean(block.id))
        .map((block) => block.id)

      const submission: DashboardBlockDragDropProviderMutation$variables["input"] = {
        dashboardId,
        mainBlockIds:
          result.source.droppableId === "drag-drop__droppable-main-blocks"
            ? moveItemWithinArray(mainBlockIds, startIndex, destinationIndex)
            : mainBlockIds,
        sideBlockIds:
          result.source.droppableId === "drag-drop__droppable-side-blocks"
            ? moveItemWithinArray(sideBlockIds, startIndex, destinationIndex)
            : sideBlockIds,
      }
      await reorderForm.submit(submission, {
        optimisticUpdater: (store) => {
          const connection = store.get(dashboardId)?.getLinkedRecord("blocks", {
            position:
              result.source.droppableId === "drag-drop__droppable-main-blocks"
                ? "main"
                : "side",
          })
          if (!connection) return
          Relay.reorderEdgeInConnection(connection, startIndex, destinationIndex)
        },
      })
    }

    // Moving between Lists
    if (result.source.droppableId !== result.destination?.droppableId) {
      let sourceArray = [...sideBlocks],
        destinationArray = [...mainBlocks]
      if (result.source.droppableId === "drag-drop__droppable-main-blocks") {
        sourceArray = [...mainBlocks]
        destinationArray = [...sideBlocks]
      }

      // Remove the correct block from the source array and insert it into the destination array
      destinationArray.splice(destinationIndex, 0, sourceArray[startIndex])
      sourceArray.splice(startIndex, 1)

      await reorderForm.submit(
        // Select the correct arrays to pass in mainBlockIds/sideBlockIds
        {
          mainBlockIds: (result.source.droppableId === "drag-drop__droppable-main-blocks"
            ? sourceArray
            : destinationArray
          ).map((block) => block.id),
          sideBlockIds: (result.source.droppableId === "drag-drop__droppable-main-blocks"
            ? destinationArray
            : sourceArray
          ).map((block) => block.id),
          dashboardId,
        },
        {
          optimisticUpdater: (store) => {
            const sourceConnection = store.get(dashboardId)?.getLinkedRecord("blocks", {
              position:
                result.source.droppableId === "drag-drop__droppable-main-blocks"
                  ? "main"
                  : "side",
            })
            const destinationConnection = store
              .get(dashboardId)
              ?.getLinkedRecord("blocks", {
                position:
                  result?.destination?.droppableId === "drag-drop__droppable-main-blocks"
                    ? "main"
                    : "side",
              })
            if (!sourceConnection || !destinationConnection) return

            Relay.moveNodeBetweenConnections(
              sourceConnection,
              destinationConnection,
              result.draggableId,
              destinationIndex
            )
          },
        }
      )
    }
  }
}

export default DashboardBlockDragDropProvider
