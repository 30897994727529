import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import { DeleteDashboardBlockButtonMutation } from "@/dashboard/delete/__generated__/DeleteDashboardBlockButtonMutation.graphql"
import useCommunityLandingPages from "@/landing-page/util/useCommunityLandingPages"
import { GlobalID } from "@/relay/RelayTypes"
import { displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

interface Props extends OverridableDiscoButtonProps {
  dashboardBlockId: GlobalID
}

function DeleteDashboardBlockButton(props: Props) {
  const { dashboardBlockId, children, ...rest } = props

  const form = useFormStore<DeleteDashboardBlockButtonMutation>(
    graphql`
      mutation DeleteDashboardBlockButtonMutation($input: DeleteDashboardBlockInput!) {
        response: deleteDashboardBlock(input: $input) {
          deletedBlockId @deleteRecord
          errors {
            field
            message
          }
        }
      }
    `,
    { dashboardBlockId }
  )

  const dashboard = useDashboardContext()!
  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()
  const { landingPages } = useCommunityLandingPages()

  return (
    <OverridableDiscoButton
      {...rest}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modal={useCallback(
        observer((modalProps) => (
          <DiscoWarningModal
            testid={"DeleteDashboardBlockButton.confirmation-modal"}
            {...modalProps}
            title={"Are you sure you want to permanently remove this block?"}
            description={
              "This action is irreversible and any customizations made to this block will be lost."
            }
            confirmationButtonProps={{
              onClick: handleDelete,
              shouldDisplaySpinner: form.isSubmitting,
              children: "Yes, remove this block",
            }}
            modalContentLabel={"Remove Block Warning"}
          />
        )),
        [dashboardBlockId]
      )}
    >
      {children}
    </OverridableDiscoButton>
  )

  async function handleDelete() {
    const { didSave } = await form.submit(form.state)
    if (didSave) {
      // Complete checklist item if we're editing one of the community landing pages
      if (landingPages.find((lp) => lp?.dashboard?.id === dashboard.id)) {
        await completeOrIgnoreChecklistItem("set_up_community_public_page")
      }

      displayToast({
        message: "Block deleted",
        testid: "DeleteDashboardBlockButton.success-toast",
      })
    }
  }
}

export default DeleteDashboardBlockButton
