import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { useDashboardContext } from "@/dashboard/context/DashboardContext"
import { DiscoCheckbox, DiscoFormControl } from "@disco-ui"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { observer } from "mobx-react-lite"
interface Props {
  form: DashboardBlockFormStore
}

function BannerDashboardBlockForm(props: Props) {
  const { form } = props
  const { banner } = form.state
  const activeProduct = useActiveProduct()
  const labels = useLabels()
  const { isPathway } = useDashboardContext()!

  if (!banner) return null

  return (
    <>
      {banner.showCover && (
        <DiscoFormControl label={"Cover Image"}>
          <DiscoImageFormField
            value={banner.cover}
            onChange={(v) => {
              banner.cover = v!.url
              banner.coverAssetId = v!.id
            }}
            disableRemove
            suggestedDimensions={{ width: 1600, height: 400 }}
            cropperProps={{
              stencilProps: { aspectRatio: ASPECT_RATIOS.BANNER },
            }}
          />
        </DiscoFormControl>
      )}

      <DiscoFormControl marginBottom={0.25}>
        <DiscoCheckbox
          label={`Show the cover image`}
          checked={Boolean(banner.showCover)}
          onChange={() => (banner.showCover = !banner.showCover)}
        />
      </DiscoFormControl>

      {activeProduct?.startsAt && (
        <>
          <DiscoFormControl marginBottom={0.25}>
            <DiscoCheckbox
              label={`Show start date & end date`}
              checked={Boolean(banner.showDates)}
              onChange={() => (banner.showDates = !banner.showDates)}
            />
          </DiscoFormControl>

          <DiscoFormControl marginBottom={0.25}>
            <DiscoCheckbox
              label={`Show countdown timer before start date`}
              checked={Boolean(banner.showCountdown)}
              onChange={() => (banner.showCountdown = !banner.showCountdown)}
            />
          </DiscoFormControl>
        </>
      )}

      {!isPathway && (
        <DiscoFormControl marginBottom={0.25}>
          <DiscoCheckbox
            label={`Show the availability of this ${labels.experience.singular}`}
            checked={Boolean(banner.showAvailability)}
            onChange={() => (banner.showAvailability = !banner.showAvailability)}
          />
        </DiscoFormControl>
      )}
    </>
  )
}

export default observer(BannerDashboardBlockForm)
