import { useCanEditDashboardFragment$key } from "@/dashboard/util/__generated__/useCanEditDashboardFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"

export default function useCanEditDashboard(
  dashboardKey?: useCanEditDashboardFragment$key | null
): boolean {
  const dashboard = useFragment(
    graphql`
      fragment useCanEditDashboardFragment on Dashboard {
        landingPageId
        app {
          kind
        }
        ...usePermissionsFragment
      }
    `,
    dashboardKey || null
  )
  const permissions = usePermissions(dashboard)
  if (!dashboard) return false

  // Community welcome page uses landing page permissions
  if (dashboard.landingPageId) return permissions.has("landing_page.edit")

  // Cannot edit app dashboards except for "dashboard" apps
  if (dashboard.app && dashboard.app.kind !== "dashboard") return false

  // Other dashboards use the dashboard permissions
  return permissions.has("dashboard.manage")
}
